import { createCheckout, getCheckout } from '@wix/ambassador-ecom-v1-checkout/http';
import { ChannelType, Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { PlanOptions } from '@wix/ambassador-pricing-plans-v3-plan/types';
import type { ControllerFlowAPI, ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { PRICING_PLANS_APP_DEF_ID } from '../../../constants';
import { EcomServerless } from '../../../services/ecom-serverless';

export class EcomService {
  constructor(private readonly flowAPI: ControllerFlowAPI | ViewerScriptFlowAPI) {}

  async createCheckout(planId: string, planOptions: PlanOptions): Promise<Checkout> {
    const {
      httpClient,
      errorHandler: { withErrorHandler },
      translations: { t },
    } = this.flowAPI;

    const serverless = new EcomServerless(this.flowAPI.httpClient);
    const address = await serverless.getAddress();

    const result = await withErrorHandler(
      () =>
        httpClient.request(
          createCheckout({
            channelType: ChannelType.WEB,
            checkoutInfo: {
              billingInfo: {
                address: {
                  country: address.country,
                  city: address.city,
                },
              },
            },
            lineItems: [
              {
                quantity: 1,
                catalogReference: {
                  appId: PRICING_PLANS_APP_DEF_ID,
                  catalogItemId: planId,
                  options: {
                    type: 'PLAN',
                    planOptions,
                  },
                },
              },
            ],
          }),
        ),
      {
        errorCodesMap: {
          applicationError: {
            SITE_MUST_ACCEPT_PAYMENTS: () => ({
              message: t('ecom-v1-checkout.application-error.site-must-accept-payments'),
            }),
            SITE_MUST_ACCEPT_PAYMENTS_TO_CREATE_CHECKOUT: () => ({
              message: t('ecom-v1-checkout.application-error.site-must-accept-payments'),
            }),

            // Coupon, Gift card, or carrier service errors are not relevant for our use case
            SELECTED_CARRIER_SERVICE_OPTION_NOT_FOUND: false,
            INVALID_SELECTED_CARRIER_SERVICE_OPTION: false,
            GIFT_CARD_NOT_FOUND: false,
            GIFT_CARD_CODE_INVALID: false,
            GIFT_CARD_BALANCE_TOO_LOW: false,
            GIFT_CARD_EXPIRED: false,
            GIFT_CARD_NOT_ACTIVE: false,
            GIFT_CARD_ALREADY_USED: false,
            GIFT_CARD_CURRENCY_MISMATCH: false,
            GIFT_CARD_AMOUNT_TOO_HIGH: false,
            GIFT_CARD_AMOUNT_TOO_LOW: false,
            GIFT_CARD_IS_EMPTY: false,
            GIFT_CARD_DISABLED: false,
            ERROR_COUPON_USAGE_EXCEEDED: false,
            ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED: false,
            ERROR_COUPON_DOES_NOT_EXIST: false,
            ERROR_COUPON_IS_NOT_ACTIVE_YET: false,
            ERROR_COUPON_HAS_EXPIRED: false,
          },
        },
      },
    );
    return result.data.checkout!;
  }

  async getCheckout(checkoutId: string): Promise<Checkout> {
    const { httpClient, errorHandler } = this.flowAPI;

    const result = await errorHandler.withErrorHandler(() => httpClient.request(getCheckout({ id: checkoutId })), {
      errorCodesMap: {
        applicationError: {},
      },
    });
    return result.data.checkout!;
  }
}
