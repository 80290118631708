import { once } from 'lodash';
import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { queryPlans } from '@wix/ambassador-pricing-plans-v3-plan/http';
import { PlanOptions, Plan, QueryV2, StartType } from '@wix/ambassador-pricing-plans-v3-plan/types';
import {
  CheckoutData,
  checkoutDataToQuery,
  commonIntegrationDataToQuery,
  IntegrationData,
  isCustomPurchaseFlow,
  TpaPageId,
} from '@wix/pricing-plans-router-utils';
import {
  type ControllerParams,
  IHttpClient,
  IWixAPI,
  ViewerScriptFlowAPI,
  ControllerFlowAPI,
} from '@wix/yoshi-flow-editor';
import { EcomService } from '../components/PlanCustomization/services/ecom';
import { PRICING_PLANS_APP_DEF_ID } from '../constants';
import { EcomServerless } from '../services/ecom-serverless';
import { getInstallationState } from './isMultiPageApp';

type WixCodeApi = ControllerParams['flowAPI']['controllerConfig']['wixCodeApi'];
type AppParams = ControllerParams['flowAPI']['controllerConfig']['appParams'];

const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

export function navigateToPlanCustomization(wixCodeApi: IWixAPI, checkoutData: CheckoutData) {
  const params = checkoutDataToQuery(checkoutData);
  // TODO: Get url from site API
  return wixCodeApi.location.to?.('/pricing-plans/plan-customization?' + params.toString());
}

export function getPlanOptions(data: {
  plan: Plan;
  submissionId?: string | null;
  startDate?: string | null;
  integrationData?: IntegrationData;
}): PlanOptions {
  const { plan, submissionId, startDate, integrationData = {} } = data;
  const pricingVariantId = plan.pricingVariants![0].id!;
  const membersOnly = isCustomPurchaseFlow(integrationData);
  const options: PlanOptions = {
    pricingVariantId,
    membersOnly,
  };

  if (submissionId) {
    options.submissionId = submissionId;
  }

  if (startDate && isStartDateValid(startDate)) {
    options.startDate = new Date(startDate);
  }

  return options;
}

export function isStartDateValid(dateString: string): boolean {
  const date = new Date(dateString);
  const today = new Date();
  if (date < today) {
    return false;
  }

  if (date.getDate() === today.getDate()) {
    return false;
  }

  return true;
}
export async function getCustomSuccessPageUrl(params: {
  checkoutId: string;
  wixCodeApi: IWixAPI;
  integrationData: IntegrationData;
  planId: string;
}): Promise<string | undefined> {
  const { wixCodeApi, integrationData, checkoutId, planId } = params;
  const { relativeUrl } = await wixCodeApi.site.getSectionUrl({
    appDefinitionId: PRICING_PLANS_APP_DEF_ID,
    sectionId: TpaPageId.ThankYou,
  });
  if (!isCustomPurchaseFlow(integrationData)) {
    return;
  }

  if (!relativeUrl) {
    return;
  }

  const continuePagePath = relativeUrl?.startsWith('/') ? relativeUrl : `/${relativeUrl}`;
  const baseUrl = wixCodeApi.location.baseUrl;
  const queryParams = commonIntegrationDataToQuery(integrationData);
  queryParams.set('isEcom', 'true');
  queryParams.set('checkoutId', checkoutId);
  queryParams.set('planId', planId);
  return encodeURIComponent(`${baseUrl}${continuePagePath}?${queryParams.toString()}`);
}

interface CreateCheckoutParams {
  flowAPI: ViewerScriptFlowAPI | ControllerFlowAPI;
  plan: Plan;
  integrationData?: IntegrationData;
  submissionId?: string | null;
  startDate?: string | null;
}

export async function createEcomCheckout(params: CreateCheckoutParams): Promise<Checkout> {
  const { submissionId, startDate, flowAPI, plan, integrationData } = params;
  const ecomService = new EcomService(flowAPI);
  const options = getPlanOptions({ plan, integrationData, submissionId, startDate });
  return ecomService.createCheckout(plan.id!, options);
}

export async function fetchPlanV3(filter: QueryV2['filter'], httpClient: IHttpClient): Promise<Plan> {
  const response = await httpClient.request(queryPlans({ query: { filter } }));
  return response.data.plans![0];
}

export function allowCustomStartDate(plan: Plan): boolean {
  return !!(plan.pricingVariants?.[0].billingTerms?.startType === StartType.CUSTOM);
}

// TODO: Define types for API
function getEcomPublicApi(wixCodeApi: IWixAPI) {
  return wixCodeApi.site.getPublicAPI(ECOM_APP_DEF_ID);
}

export async function navigateToEcomCheckout(params: {
  checkoutId: string;
  planId: string;
  wixCodeApi: IWixAPI;
  integrationData?: IntegrationData;
}) {
  const { checkoutId, planId, wixCodeApi, integrationData } = params;
  const successUrl = await getCustomSuccessPageUrl({
    wixCodeApi,
    integrationData: integrationData ?? {},
    checkoutId,
    planId,
  });
  const api = await getEcomPublicApi(wixCodeApi);
  return api.navigate.toCheckout({
    checkoutId,
    disableContinueShopping: true,
    successUrl,
  });
}
export const isViewerOnEcom = once(
  async (httpClient: IHttpClient, wixCodeApi: WixCodeApi, appParams: AppParams): Promise<boolean> => {
    const ecom = new EcomServerless(httpClient);
    const { hasRouter, isMpaPagesInstalled, isMpaEcomPagesInstalled } = await getInstallationState(
      wixCodeApi,
      appParams,
    );
    if (hasRouter) {
      if (isMpaEcomPagesInstalled && !isMpaPagesInstalled) {
        return true;
      }
      return ecom.isInPopulation();
    }
    return false;
  },
);
