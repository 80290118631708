import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  checkoutStage,
  membershipStatusLoaded,
  thankYouPageCtaButtonClick,
  upgradeReferralClick,
} from '@wix/bi-logger-pricing-plans-data/v2';
import { CheckoutData, EcomContinuePublicData, IntegrationData, StatusData } from '@wix/pricing-plans-router-utils';
import { getThankYouPageSettings } from '@wix/pricing-plans-utils/client-data';
import { retry, RetryOptions } from '@wix/pricing-plans-utils/http';
import { navigateToHeadlessIfNeeded, NavigationType } from '@wix/wix-to-headless-redirect-client';
import { ControllerFlowAPI, ControllerParams } from '@wix/yoshi-flow-editor';
import { PlansApi } from '../../../services';
import { BenefitPrograms } from '../../../services/benefit-programs';
import { WarmupData } from '../../../services/WarmupData';
import { CheckoutStage, StatusProps } from '../../../types/common';
import { toMembershipStatus } from '../../../utils/bi';
import { toError } from '../../../utils/errors';
import { getPageType } from '../../../utils/pageType';
import { captureViewerException } from '../../../utils/viewer-errors';
import { EcomService } from '../../PlanCustomization/services/ecom';
import { Router } from './Router';

export class RetryUntilOrderProvisionError extends Error {
  constructor() {
    super('Retry - Order is not provisioned');
  }
}

class OrderIdMissingError extends Error {
  constructor() {
    super('Order ID is missing on checkout entity');
  }
}

interface OrderProvisionedPollingOptions {
  isEcom?: boolean;
}

export class StatusController {
  constructor(
    protected setProps: (props: Partial<StatusProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected flowAPI: ControllerFlowAPI,
    protected router: Router,
    protected plansApi: PlansApi,
    protected warmupData: WarmupData,
    protected benefitPrograms: BenefitPrograms,
    protected readonly orderRetryOptions: RetryOptions = { delay: 500, times: 4, backoff: 3 },
  ) {}

  public async initialize(statusData: StatusData) {
    const { planId } = statusData.purchaseData.checkoutData;

    let plans: PublicPlan[] = [];
    try {
      plans = await this.warmupData.cache('status.plan', () => this.plansApi.loadPaidPlans({ planIds: [planId] }));
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }

    await this.update(statusData, plans[0]);
  }

  private async setupOrderProvisionedPolling(orderId: string, options?: OrderProvisionedPollingOptions) {
    if (this.flowAPI.environment.isSSR) {
      return this.setProps({ isOrderProvisioned: false });
    }
    this.flowAPI.fedops.interactionStarted('order_provision');
    try {
      const isEcom = options?.isEcom ?? false;
      await retry(this.orderRetryOptions, async () => {
        const result = isEcom
          ? await this.benefitPrograms.isEcomOrderProvisioned(orderId)
          : await this.benefitPrograms.isOrderProvisioned(orderId);
        if (!result) {
          throw new RetryUntilOrderProvisionError();
        }
      });
    } catch (err) {
      captureViewerException(this.flowAPI, err, {
        additionalIgnorePredicates: [(e) => e instanceof RetryUntilOrderProvisionError],
        tags: {
          interaction: 'order_provision',
        },
      });
    } finally {
      this.flowAPI.fedops.interactionEnded('order_provision');
      this.setProps({ isOrderProvisioned: true });
    }
  }

  private shouldSkipOrderProvisionedPolling(isOwnerDemo?: boolean): boolean {
    return Boolean(
      this.wixCodeApi.user.currentUser.loggedIn === false || !this.flowAPI.environment.isViewer || isOwnerDemo,
    );
  }

  public async update(statusData: StatusData, plan?: PublicPlan) {
    const { ownerDemo, successful, purchaseData, error, startDate } = statusData;
    const { checkoutData, orderId } = purchaseData;
    const { integrationData, planId, guestCheckoutEnabled } = checkoutData;

    if (this.shouldSkipOrderProvisionedPolling(ownerDemo)) {
      this.setProps({ isOrderProvisioned: true });
    } else {
      void this.setupOrderProvisionedPolling(orderId);
    }

    this.flowAPI.bi?.report(
      membershipStatusLoaded({
        membershipStatus: toMembershipStatus(getPageType({ ownerDemo, successful }, integrationData)),
      }),
    );
    this.setProps({
      settings: getThankYouPageSettings(plan?.clientData),
      successful,
      translatedError: error,
      startDate,
      ownerDemo: statusData.ownerDemo,
      integrationData: statusData.purchaseData.checkoutData.integrationData,
      metaSiteId: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId,
      biUpgradeReferralClick: (referralInfo: string) =>
        this.flowAPI.bi?.report(upgradeReferralClick({ referralInfo, isPreview: this.flowAPI.environment.isPreview })),
      navigateFromStatusPage: () => this.navigateFromStatusPage(statusData, plan),
      navigateBackToCheckout: () => this.navigateBackToCheckout(integrationData, plan),
      biThankYouPageCtaButtonClick: () => this.flowAPI.bi?.report(thankYouPageCtaButtonClick({ buttonName: 'null' })),
      biThankYouPageOnLoad: () =>
        this.flowAPI.bi?.report(
          checkoutStage({
            stage: CheckoutStage.THANK_YOU_PAGE,
            planGuid: planId,
            guestCheckout: Boolean(guestCheckoutEnabled),
          }),
        ),
    });
  }

  initializeForEcomContinue(data: EcomContinuePublicData) {
    const ecom = new EcomService(this.flowAPI);
    const { checkoutData, checkoutId } = data.continueData;
    const { integrationData } = checkoutData;

    if (this.shouldSkipOrderProvisionedPolling()) {
      this.setProps({ isOrderProvisioned: true });
    } else {
      retry({ times: 3, delay: 500 }, async () => {
        const checkout = await ecom.getCheckout(checkoutId);
        if (!checkout.orderId) {
          throw new OrderIdMissingError();
        }

        return checkout.orderId;
      })
        .then((orderId) => this.setupOrderProvisionedPolling(orderId, { isEcom: true }))
        .catch((err) => {
          captureViewerException(this.flowAPI, err);
          this.setProps({ isOrderProvisioned: true });
        });
    }

    this.setProps({
      successful: true,
      integrationData,
      metaSiteId: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId,
      navigateFromStatusPage: () =>
        this.navigateTo({
          // TODO: Add planName and startDate
          integrationData,
        }),
      // TODO: Add BI logging
      biUpgradeReferralClick: () => {},
      biThankYouPageCtaButtonClick: () => {},
      biThankYouPageOnLoad: () => {},
    });
  }

  navigateFromStatusPage(statusData: StatusData, plan?: PublicPlan) {
    const {
      planName,
      startDate,
      purchaseData: {
        checkoutData: { integrationData },
      },
    } = statusData;
    if (integrationData.navigateTo || integrationData.navigateToSectionProps || integrationData.navigateToPageProps) {
      this.navigateTo({
        planName,
        startDate,
        integrationData,
      });
    } else {
      const settings = getThankYouPageSettings(plan?.clientData);
      if (settings?.buttonLink) {
        this.wixCodeApi.location.to!(settings?.buttonLink);
      } else {
        navigateToHeadlessIfNeeded({
          navParams: {
            logicalName: NavigationType.PAID_PLANS_CONTINUE_BROWSING,
          },
          location: this.wixCodeApi.location,
          fallbackNavigation: () => this.router.gotoHomePage(),
        });
      }
    }
  }

  async navigateTo({
    planName,
    startDate,
    integrationData: { navigateToPageProps, navigateToSectionProps, navigateTo },
  }: Pick<StatusData, 'planName' | 'startDate'> & Pick<CheckoutData, 'integrationData'>) {
    if (navigateToSectionProps) {
      if (startDate) {
        navigateToSectionProps.queryParams = {
          ...(navigateToSectionProps.queryParams ?? {}),
          startDate,
          planName,
        };
      }

      const { queryParams, state, ...sectionProps } = navigateToSectionProps;
      const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl(sectionProps);

      let url = relativeUrl + '';
      if (state) {
        url += '/' + state;
      }
      if (queryParams) {
        url += '?appSectionParams=' + encodeURIComponent(JSON.stringify(queryParams));
      }
      this.wixCodeApi.location.to!(url);
    } else if (navigateToPageProps) {
      this.wixCodeApi.location.navigateTo!({ pageId: navigateToPageProps });
    } else if (navigateTo) {
      const { baseUrl } = this.wixCodeApi.location;
      if (navigateTo.startsWith('/') || navigateTo.startsWith(baseUrl)) {
        const [path, params] = toPathAndSearchParams(navigateTo);
        if (planName) {
          params.append('planName', planName);
        }
        if (startDate) {
          params.append('startDate', startDate);
        }
        const search = params.toString();
        this.wixCodeApi.location.to!(path + (search ? '?' + search : ''));
      } else {
        // Navigation outside website with our open Integrations API is dangerous as it could lead to spoofing
        // attacks. This can be allowed if final URL is validated against whitelist, e.g. something entered in
        // website/component settings.
        this.flowAPI.errorMonitor.captureMessage(`Blocked navigation to ${navigateTo} from ${baseUrl}.`);
        this.router.gotoHomePage();
      }
    }
  }

  async navigateBackToCheckout(integrationData: IntegrationData, plan?: PublicPlan) {
    if (plan) {
      this.router.gotoCheckout(plan, integrationData);
    } else {
      this.router.gotoHomePage();
    }
  }
}

function toPathAndSearchParams(relativeUrl: string): [string, URLSearchParams] {
  const queryStart = relativeUrl.indexOf('?');
  const [path, query] =
    queryStart > -1 ? [relativeUrl.substring(0, queryStart), relativeUrl.substring(queryStart)] : [relativeUrl, ''];
  return [path, new URLSearchParams(query)];
}
